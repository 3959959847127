<template>
    <edit-view
        :view="editView"
        :allow-delete="name === undefined"
        v-on:update-view="updateViewToTeam"
        v-on:delete-view="deleteViewToTeam"
        v-on:cancel="cancelUpdate"
    />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import editView from '@/views/views/edit';

export default {
    name: 'team-view-edit',
    data: () => ({
        editView: undefined,
    }),
    props: {},
    components: {
        editView,
    },
    mounted() {
        this.initEditingView();
    },
    props: {
        name: {
            default: undefined,
        },
    },
    watch: {
        team: function (value) {
            if (value) {
                this.initEditingView();
            }
        },
    },
    computed: {
        ...mapGetters(['team']),
    },
    methods: {
        ...mapActions(['updateTeamView', 'deleteTeamView']),
        initEditingView() {
            if (this.team) {
                if (this.name) {
                    this.editView = this.team.views.find((v) => v.name === this.name);
                } else {
                    this.editView = this.team.views.find((v) => v.id === this.$route.params.view_id);
                }
            }
        },
        cancelUpdate() {
            if (!this.name) {
                this.$router.go(-1);
            }
        },
        deleteViewToTeam(view) {
            const payload = {
                team: this.team.id,
                view: view,
            };

            this.deleteTeamView(payload);
            if (!this.name) {
                this.$router.go(-1);
            }
        },
        updateViewToTeam(view) {
            const payload = {
                team: this.team.id,
                view: view,
            };

            this.updateTeamView(payload);
            if (!this.name) {
                this.$router.go(-1);
            }
        },
    },
};
</script>
